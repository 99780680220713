// Configuration
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

$small-font-size: 0.625em;

$utilities: (
  "background-attachment": (
    property: background-attachment,
    class: bg-attachment,
    values: scroll fixed,
  ),
);

@import "~bootstrap/scss/utilities";

body {
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal;
  }
}

@include media-breakpoint-up(md) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

$utilities: map-merge(
  $utilities,
  (
    "background-color":
      map-merge(
        map-get($utilities, "background-color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "background-color"), "values"),
              (
                "lightgray": $gray-100,
                "gray": $gray-300,
                "darkgray": #8a8a8a,
              )
            ),
        )
      ),
  )
);

$dealersite-form-color: $white;
$dealersite-invert-form: false;
